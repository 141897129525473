import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  games: [
    {
      id: '',
      company_id: '',
      name: {
        en: '',
        ko: '',
      },
      image: '',
      banner: '',
      description: {
        en: '',
        ko: '',
      },
      vol_24h: '',
      vol_24h_rate: 0,
      total_vol: '',
      floor_price: '',
      owner_count: 0,
      item_count: 0,
      links: [
        {
          type: '',
          url: '',
        },
      ],
      company: {
        id: '',
        name: {
          en: '',
          ko: '',
        },
        image: '',
        banner: '',
        description: {
          en: '',
          ko: '',
        },
      },
      status: '',
    },
  ],
};

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    initGames: (state) => {
      state.games = initialState.games;
    },

    setGames: (state, action) => {
      state.games = action.payload;
    },
  },
});

export const { initGames, setGames } = gamesSlice.actions;

export default gamesSlice.reducer;
