import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionTypes } from '~/types/SubscriptionTypes';

const initialState: SubscriptionTypes = {
  subscription: {
    id: null,
    name: { ko: '', en: '' },
    introduction: { ko: '', en: '' },
    stakingTokenId: null,
    mysteryBoxId: null,
    useWhitelistFiltering: null,
    whitelistNftIds: null,
    useAndCondition: null,
    creatorAddress: null,
    status: null,
    updatedAt: null,
    createdAt: null,
    mechanism: null,
    calculationSetup: null,
    launchDatetime: null,
    durationTime: null,
    deployDatetime: null,
    contractAddress: null,
    deployStatus: null,
    creator: null,
    klaybayFee: undefined,
  },
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,

  reducers: {
    initSubscription: (state) => {
      state.subscription = initialState.subscription;
    },
    createSubscriptionStore: (state, action) => {
      state.subscription = action.payload.data.subscription;
    },
  },
});

export const { initSubscription, createSubscriptionStore } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
