import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featured: {
    id: '',
    company_id: '',
    name: {
      en: '',
      ko: '',
    },
    image: '',
    banner: '',
    eventBanner: '',
    eventUrl: '',
    description: {
      en: '',
      ko: '',
    },
    vol_24h: '',
    vol_24h_rate: 0,
    total_vol: '',
    floor_price: '',
    owner_count: 0,
    item_count: 0,
    links: [
      {
        type: '',
        url: '',
        useExternalUrl: '',
      },
    ],
    manager_wallet_address: [],
  },
};

const featuredSlice = createSlice({
  name: 'featured',
  initialState,
  reducers: {
    initFeatured: (state) => {
      state.featured = initialState.featured;
    },

    setFeatured: (state, action) => {
      state.featured = action.payload;
    },
  },
});

export const { initFeatured, setFeatured } = featuredSlice.actions;

export default featuredSlice.reducer;
