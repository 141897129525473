import React, { useEffect } from 'react';
import './App.css';
import './design/sass/main.scss';
import './components/togglelightmode/toggle-mode.css';
import { useRoutes, useLocation } from 'react-router-dom';
import ScrollToTop from '~/components/common/ScrollToTop';
import Router from '~/routes/Router';
import { useThemeMode } from './components/common/AppStoreStyle';
import GoogleAnalytics from 'react-ga4';

function App() {
  const routing = useRoutes(Router());
  const { pathname } = useLocation();

  //set dark light mode
  const { lightMode, darkMode } = useThemeMode();
  const setDark = () => {
    localStorage.setItem('theme', 'dark');
    document.documentElement.setAttribute('data-theme', 'dark');
    darkMode();
  };

  const setLight = () => {
    localStorage.setItem('theme', 'light');
    document.documentElement.setAttribute('data-theme', 'light');
    lightMode();
  };
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const defaultDark = storedTheme === 'dark' || storedTheme === null;

    if (defaultDark) {
      setDark();
    } else {
      setLight();
    }
  }, []);

  GoogleAnalytics.set({
    pathname,
    title: 'studio.talken.io' + pathname,
  });
  GoogleAnalytics.send({ hitType: 'pageview', page: pathname });
  return (
    <>
      <ScrollToTop />
      {routing}
    </>
  );

  // return (
  //   <ThemeProvider theme={theme}>
  //     <Box className="App">{routing}</Box>
  //   </ThemeProvider>
  // );
}

export default App;
