import { combineReducers } from 'redux';
import wallet from '~/redux/slices/wallet';
import mysteryBox from '~/redux/slices/mysteryBox';
import whitelist from '~/redux/slices/whitelist';
import subscription from '~/redux/slices/subscription';
import company from '~/redux/slices/company';
import games from '~/redux/slices/games';
import account from '~/redux/slices/account';
import game from '~/redux/slices/game';
import featured from '~/redux/slices/featured';
import featuredList from '~/redux/slices/featuredList';

const RootReducers = combineReducers({
  wallet,
  mysteryBox,
  whitelist,
  subscription,
  company,
  games,
  account,
  game,
  featured,
  featuredList,
});

export default RootReducers;
