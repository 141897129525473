import { createSlice } from '@reduxjs/toolkit';
import { MysteryBoxItemTypes, MysteryBoxStateTypes } from '~/types';

const initialState: MysteryBoxStateTypes = {
  info: {
    id: null,
    title: { ko: '', en: '' },
    symbol: null,
    introduction: { ko: '', en: '' },
    bannerImage: null,
    price: null,
    quote: null,
    status: null,
    updatedAt: null,
    createdAt: null,
    itemAmount: null,
    isApproved: null,
    rarityLevel: null,
    packageImage: null,
    revealAnimation: null,
    releaseDatetime: null,
    afterRelease: null,
    keyContractAddress: null,
    boxContractAddress: null,
    deployStatus: null,
    creator: null,
    useSubscription: false,
    useRevealLockup: false,
    useWhitelistFiltering: false,
    whitelistNftId: null,
    paymentAddress: null,
    subscriptionId: null,
    totalAmount: null,
    usedAmount: null,
    whitelists: null,
    useAndCondition: null,
    dropsOpen: null,
    dropsOpenDatetime: null,
    useSoulbound: null,
    useOnetime: null,
    useCategory: null,
    categories: null,
  },
  items: [],
  tempItems: [],
  itemsAmount: [],
  totalAmount: 0,
};

const mysteryBoxSlice = createSlice({
  name: 'mysteryBox',
  initialState,
  reducers: {
    initBoxInfo: (state) => {
      state.info = initialState.info;
    },
    createBoxInfo: (state, action) => {
      state.info = action.payload.data.info;
    },
    initBoxItem: (state) => {
      state.items = [];
    },
    initTempBoxItem: (state) => {
      state.tempItems = [];
    },
    initItemsAmount: (state) => {
      state.itemsAmount = [];
    },

    saveBoxItem: (state, action) => {
      if (action.payload.data.item) {
        const filteredItem: MysteryBoxItemTypes[] = state.items.filter(
          (item) => item.id !== action.payload.data.item.id
        );
        state.items = [...filteredItem, { ...action.payload.data.item }];
      }

      if (action.payload.data.items) {
        const items: MysteryBoxItemTypes[] = action.payload.data.items.map(
          (item: MysteryBoxItemTypes) => {
            return { ...item, isSaved: true };
          }
        );
        state.items = items;
      }
    },

    saveTempBoxItem: (state, action) => {
      if (action.payload.data != undefined) {
        state.tempItems = action.payload.data.items;
        return;
      }

      const newItem = state.tempItems.find(
        (item) => item.no === action.payload.no
      );
      if (!newItem) {
        state.tempItems = [...state.tempItems, action.payload];
      }

      const newTempItems = state.tempItems.map((item) =>
        item.no === action.payload.no ? { ...action.payload } : { ...item }
      );

      state.tempItems = newTempItems;
    },

    addItemsAmount: (state, action) => {
      const checkIdAmount = state.itemsAmount.find(
        (item) => item.id === action.payload.id
      );

      if (!checkIdAmount) {
        state.itemsAmount = [...state.itemsAmount, action.payload];
      }

      const newTempItems = state.itemsAmount.map((item) =>
        item.id === action.payload.id ? { ...action.payload } : { ...item }
      );

      state.itemsAmount = newTempItems;
    },
  },
});

export const {
  createBoxInfo,
  saveBoxItem,
  initBoxInfo,
  initBoxItem,
  initTempBoxItem,
  initItemsAmount,
  saveTempBoxItem,
  // updateProbability,
  addItemsAmount,
} = mysteryBoxSlice.actions;
export default mysteryBoxSlice.reducer;
