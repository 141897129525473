import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featuredList: [
    {
      id: '',
      company_id: '',
      name: {
        en: '',
        ko: '',
      },
      image: '',
      banner: '',
      description: {
        en: '',
        ko: '',
      },
      vol_24h: '',
      vol_24h_rate: 0,
      total_vol: '',
      floor_price: '',
      owner_count: 0,
      item_count: 0,
      links: [
        {
          type: '',
          url: '',
        },
      ],
      company: {
        id: '',
        name: {
          en: '',
          ko: '',
        },
        image: '',
        banner: '',
        description: {
          en: '',
          ko: '',
        },
      },
      status: '',
    },
  ],
};

const featuredListSlice = createSlice({
  name: 'featuredList',
  initialState,
  reducers: {
    initFeaturedList: (state) => {
      state.featuredList = initialState.featuredList;
    },

    setFeaturedList: (state, action) => {
      state.featuredList = action.payload;
    },
  },
});

export const { initFeaturedList, setFeaturedList } = featuredListSlice.actions;

export default featuredListSlice.reducer;
