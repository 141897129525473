import create from 'zustand';
type themeModeType = {
  isDarkMode: boolean;
  lightMode: () => void;
  darkMode: () => void;
};
export const useThemeMode = create<themeModeType>((set) => ({
  isDarkMode: false,
  lightMode: () => set({ isDarkMode: false }),
  darkMode: () => set({ isDarkMode: true }),
}));
export default 1;
