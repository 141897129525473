import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  game: {
    id: '',
    company_id: '',
    name: {
      en: '',
      ko: '',
    },
    image: '',
    banner: '',
    description: {
      en: '',
      ko: '',
    },
    vol_24h: '',
    vol_24h_rate: 0,
    total_vol: '',
    floor_price: '',
    owner_count: 0,
    item_count: 0,
    links: [
      {
        type: '',
        url: '',
      },
    ],
    manager_wallet_address: [],
  },
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    initGame: (state) => {
      state.game = initialState.game;
    },

    setGame: (state, action) => {
      state.game = action.payload;
    },
  },
});

export const { initGame, setGame } = gameSlice.actions;

export default gameSlice.reducer;
