import { createSlice } from '@reduxjs/toolkit';
import { WhitelistNftTypes } from '~/types';

interface WalletAddressType {
  id: number;
  name: string;
  whitelistNftId: number;
  round: number;
  addresses: string[];
  isMinted: boolean;
  mintingDatetime: Date;
}

const initialState: WhitelistNftTypes = {
  nft: {
    id: null,
    contractAddress: null,
    creator: null,
    creatorAddress: null,
    deployDatetime: null,
    deployStatus: null,
    image: null,
    imageLink: null,
    introduction: null,
    isExternal: null,
    metaLink: null,
    mintingAddresses: null,
    name: null,
    status: null,
    useOnetime: null,
    useSoulbound: null,
    createdAt: null,
    updatedAt: null,
  },
  addresses: [],
  selectedRound: null,
};

const whitelistNftSlice = createSlice({
  name: 'whitelist',
  initialState,

  reducers: {
    initWhiteList: (state) => {
      state.nft = {
        id: null,
        contractAddress: null,
        creator: null,
        creatorAddress: null,
        deployDatetime: null,
        deployStatus: null,
        image: null,
        imageLink: null,
        introduction: null,
        isExternal: null,
        metaLink: null,
        mintingAddresses: null,
        name: null,
        status: null,
        useOnetime: null,
        useSoulbound: null,
        createdAt: null,
        updatedAt: null,
      };
      state.addresses = [];
      state.selectedRound = null;
    },
    initSelectedRound: (state) => {
      state.selectedRound = null;
    },
    createWhiteList: (state, action) => {
      state.nft = action.payload.data.nft;
    },
    updateAllAddresses: (state, action) => {
      state.addresses = action.payload.data.addresses;
    },

    updateAddresses: (state, action) => {
      const newAddress = state.addresses.find(
        (address) => address.id === action.payload.data.addresses.id
      );

      if (newAddress === undefined)
        state.addresses = [action.payload.data.addresses, ...state.addresses];
      else
        state.addresses = state.addresses.map((address) =>
          address.id === action.payload.data.addresses.id
            ? { ...action.payload.data.addresses }
            : address
        );
    },
    removeAddress: (state, action) => {
      const newAddresses = state.addresses.filter(
        (address) => address.round !== action.payload
      );
      state.addresses = newAddresses;
    },
    addSelectedRound: (state, action) => {
      state.selectedRound = action.payload;
    },
  },
});

export const {
  initWhiteList,
  initSelectedRound,
  createWhiteList,
  updateAllAddresses,
  updateAddresses,
  removeAddress,
  addSelectedRound,
} = whitelistNftSlice.actions;
export default whitelistNftSlice.reducer;
