import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { Buffer } from 'buffer';
import '~/locales/i18n';
import GoogleAnalytics from 'react-ga4';

import { Provider } from 'react-redux';
import { configureStore } from './redux/Store';

import { ethers } from 'taalswap-ethers';
import { Web3ReactProvider } from '@web3-react/core';

import { render } from 'react-dom';

// import { Web3Provider } from '@ethersproject/providers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const isProduction = process.env.REACT_APP_PHASE === 'production';

if (isProduction) {
  GoogleAnalytics.initialize('G-22MB7D0G15');
} else {
  GoogleAnalytics.initialize('G-2LWEVXXBQF');
}

// function getLibrary(provider: any): Web3Provider {
function getLibrary(provider: any) {
  // const library = new Web3Provider(provider);
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

// (walletconnector) :connect wallet error ReferenceError: Buffer is not defined
window.Buffer = window.Buffer || Buffer;

// root.render(
//   <React.StrictMode>
//     <CssBaseline>
//       <Web3ReactProvider getLibrary={getLibrary}>
//         <Provider store={configureStore()}>
//           <BrowserRouter>
//             <App />
//           </BrowserRouter>
//         </Provider>
//       </Web3ReactProvider>
//     </CssBaseline>
//   </React.StrictMode>
// );

render(
  <React.StrictMode>
    <CssBaseline>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={configureStore()}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </Web3ReactProvider>
    </CssBaseline>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
