import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  company: {
    id: '',
    name: { en: '', kr: '' },
    description: { en: '', kr: '' },
    image: '',
    banner: '',
  },
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    initCompany: (state) => {
      state.company = {
        id: '',
        name: { en: '', kr: '' },
        description: { en: '', kr: '' },
        image: '',
        banner: '',
      };
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
  },
});

export const { initCompany, setCompany } = companySlice.actions;

export default companySlice.reducer;
